@tailwind base;
@tailwind components;
@tailwind utilities;

/* LANDING PAGE -------------------------------------------------------------------------------- */
.landing {
    background-size: cover;
    background-position-x: 20vw;
    background-position-y: 10vh;
    background-repeat: no-repeat;
}
.landing.dark {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHZpZXdCb3g9IjAgMCAxNDgwIDY1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0MTRfMzQ4MCkiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTczMS4yMDcgNjQ5LjgwMkM5MzUuNDg0IDY0Mi4xNCAxNDgwIDMzNy4zMjUgMTQ4MCAxODAuODg4QzE0ODAgMjQuNDUwMiAxMTcwLjI2IDE0NC44MzMgNzYwIDE0NC44MzNDMzQ5Ljc0NCAxNDQuODMzIDAgLTE3NC40ODMgMCAxMzUuMTQ0QzAgNDQ0Ljc3MSA1MjYuOTMgNjU3LjQ2NCA3MzEuMjA3IDY0OS44MDJaIiBmaWxsPSIjMTIxMjEyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNDQxNF8zNDgwIj4KPHJlY3Qgd2lkdGg9IjE0ODAiIGhlaWdodD0iNjUwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=);
}
.landing.light{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik03MzEuMjA3IDY0OS44MDJDOTM1LjQ4NCA2NDIuMTQgMTQ4MCAzMzcuMzI1IDE0ODAgMTgwLjg4OGMwLTE1Ni40MzgtMzA5Ljc0NC0zNi4wNTUtNzIwLTM2LjA1NVMwLTE3NC40ODMgMCAxMzUuMTQ0YzAgMzA5LjYyNyA1MjYuOTMgNTIyLjMyIDczMS4yMDcgNTE0LjY1OHoiIGZpbGw9IiNGNkY4RkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}

/* SQUILL EDITOR -------------------------------------------------------------------------------- */
.ql-container {
    font-family: inherit !important;
    min-height: 6rem;
    font-size: var(--nextui-font-size-small) !important;
    line-height: var(--nextui-line-height-small) !important;
}

.ql-container.ql-snow {
    border-width: 0px !important
}

.ql-toolbar.ql-snow {
    border: 0px !important
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    color: hsl(var(--nextui-foreground));
}

.ql-toolbar > button > svg > * {
    stroke: hsl(var(--nextui-foreground)) !important;
}

/* ICONS -------------------------------------------------------------------------------- */
.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 200,
        'GRAD' 0,
        'opsz' 24
}

.material-symbols-outlined.filled {
    font-variation-settings:
        'FILL' 1,
        'wght' 200,
        'GRAD' 0,
        'opsz' 24
}